@font-face {
    font-family: "NotoSansKR";
    src: local("NotoSansKR-Thin"),
        url("https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2") format("woff2");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "NotoSansKR";
    src: local("NotoSansKR-Light"),
        url("https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "NotoSansKR";
    src: local("NotoSansKR-Regular"),
        url("https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "NotoSansKR";
    src: local("NotoSansKR-Medium"),
        url("https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "NotoSansKR";
    src: local("NotoSansKR-Bold"),
        url("https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "NotoSansKR";
    src: local("NotoSansKR-Black"),
        url("https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2") format("woff2");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "LINESeedKR";
    src: local("LINESeedKR-Th"),
        url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/LINESeedKR-Th.woff2") format("woff2");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "LINESeedKR";
    src: local("LINESeedKR-Rg"),
        url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/LINESeedKR-Rg.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "LINESeedKR";
    src: local("LINESeedKR-Bd"),
        url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/LINESeedKR-Bd.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}

:root {
    --color-White: #fff;
    --color-Black: #231e1e;
    --color-SubBlack: #505550;
    --color-Grey1: #878282;
    --color-Grey2: #b4b9b4;
    --color-Grey3: #e6ebe6;
    --color-Outline: #cfd4d4;
    --color-Line: #dce4e4;
    --color-Base1: #f2f8f6;
    --color-Base2: #e2ede9;
    --color-BaseBlue: #eef3f5;
    --color-InfoBox: #f3f6f6;
    --color-InputLine: #dbe5e4;
    --color-DisabledButton: #c8d0ce;
    --color-DisabledText: #bdc6c4;
    --color-DisabledInput: #e7eceb;
    --color-Tag: #d8e3e8;
    --color-TagActive: #a6daee;
    --color-Research: #d4e1dd;
    --color-ResearchActive: #96e7d3;
    --color-Key: #009a7e;
    --color-Sub: #00c4d0;
    --color-DarkKey: #00725d;
    --color-Background: #edf5f4;
    --color-Button1: #08b7a1;
    --color-Button2: #10564d;
    --color-Button3: #7fafa7;
    --color-Button4: #eaf4f2;
    --color-ButtonHover1: #117266;
    --color-ButtonHover2: #042420;
    --color-ButtonHover3: #558184;
    --color-ButtonHover4: #d6eae6;
    --color-ButtonHover5: #b9d9d2;
    --color-CodeBack: #30363f;
    --color-Warn: #e85757;
    --color-WarnHover: #c22121;
    --color-TitleBar: #8cd7bc;
    --color-QuoteBox: #d4e2ee;
    --color-HighlightRed: #df2f2f;
    --color-HighlightBlue: #5caaf2;
    --color-HighlightYellow: #ecc55f;
    --color-HighlightGreen: #94ef5d;
    --color-HighlightPurple: #a473f5;
}

*,
ul,
li,
ol {
    margin: 0;
    padding: 0;
}

html {
    width: 100%;
    height: 100%;
    font-size: 10px;
}

body {
    width: 100%;
    background-color: var(--color-BgBlue);
}

#root {
    height: 100%;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: "NotoSansKR", sans-serif;
}

p,
li,
label,
input,
textarea,
div {
    font-family: "NotoSansKR", sans-serif;
}

a {
    text-decoration: none !important;
}

input:disabled {
    background-color: var(--color-DisabledInput) !important;
    border-color: var(--color-Outline) !important;
}

select {
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select::-ms-expand {
    display: none;
}

// 화살표 이미지 무한반복

.downArrow {
    &:hover {
        animation-name: downArrow;
        animation-duration: 500ms;
        animation-delay: unset;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }
}

@keyframes downArrow {
    0% {
        bottom: 115px;
    }

    100% {
        bottom: 125px;
    }
}

// 로딩 이미지

.circle1 {
    animation-name: circle1;
}

.circle2 {
    animation-name: circle2;
}

.circle3 {
    animation-name: circle3;
}

.circle4 {
    animation-name: circle4;
}

.circle5 {
    animation-name: circle5;
}

.circle1,
.circle2,
.circle3,
.circle4,
.circle5 {
    animation-duration: 1500ms;
    animation-delay: unset;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: revert;
}

@keyframes circle1 {
    0% {
        transform: scale(1);
        background-color: var(--color-DarkKey);
    }

    20% {
        transform: scale(1.5);
        background-color: var(--color-Key);
    }

    40% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
        background-color: var(--color-DarkKey);
    }
}

@keyframes circle2 {
    0% {
        transform: scale(1);
    }

    10% {
        transform: scale(1);
        background-color: var(--color-DarkKey);
    }

    30% {
        transform: scale(1.5);
        background-color: var(--color-Key);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
        background-color: var(--color-DarkKey);
    }
}

@keyframes circle3 {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(1);
    }

    40% {
        transform: scale(1.5);
        background-color: var(--color-Key);
    }

    60% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
        background-color: var(--color-DarkKey);
    }
}

@keyframes circle4 {
    0% {
        transform: scale(1);
    }

    30% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
        background-color: var(--color-Key);
    }

    70% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
        background-color: var(--color-DarkKey);
    }
}

@keyframes circle5 {
    0% {
        transform: scale(1);
    }

    40% {
        transform: scale(1);
    }

    60% {
        transform: scale(1.5);
        background-color: var(--color-Key);
    }

    80% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
        background-color: var(--color-DarkKey);
    }
}

// 모달 페이드인

.modalContainer {
    animation-name: modalFadeIn;
    animation-duration: 300ms;
    animation-delay: unset;
    animation-iteration-count: initial;
    animation-direction: normal;
    animation-timing-function: ease-in-out;
}

@keyframes modalFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// 얼럿 페이드인

.alert {
    animation-name: alertFadeIn;
    animation-duration: 100ms;
    animation-delay: unset;
    animation-iteration-count: initial;
    animation-direction: normal;
    animation-timing-function: ease-in-out;
}

@keyframes alertFadeIn {
    0% {
        opacity: 0;
        top: 12px;
    }

    100% {
        opacity: 1;
        top: 0;
    }
}

// 토스트 페이드인

.toastAnimation {
    animation-name: toastFadeIn;
    animation-duration: 3000ms;
    animation-delay: unset;
    animation-iteration-count: initial;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}

@keyframes toastFadeIn {
    0% {
        opacity: 0;
        top: 48px;
    }

    10% {
        opacity: 1;
        top: 40px;
    }

    90% {
        opacity: 1;
        top: 40px;
    }

    100% {
        opacity: 0;
        top: 32px;
    }
}

// 리뷰로드

.reviewLoad {
    animation-name: reviewLoad;
    animation-duration: 1500ms;
    animation-delay: unset;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: linear;
}

@keyframes reviewLoad {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.imp-dialog.customizable {
    width: 800px;
    height: 500px;
    top: calc(50% - 250px);
    left: calc(50% - 400px);
}
