:root {
    --rpv-highlight__selected-text-background-color: rgba(255, 255, 0, 0.4);
    --rpv-highlight__click-drag-border-color: #2566e8;
    --rpv-highlight__click-drag-background-color: rgba(255, 255, 0, 0.4);
}
.rpv-highlight__click-drag {
    background-color: var(--rpv-highlight__click-drag-background-color);
    outline: 1px dashed var(--rpv-highlight__click-drag-border-color);
    position: absolute;
    z-index: 2;
}
.rpv-highlight__click-drag--hidden {
    display: none;
}
.rpv-highlight__selected-text {
    background-color: var(--rpv-highlight__selected-text-background-color);
}
.rpv-highlight__selected-end {
    display: block;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: -1;
    user-select: none;
}
